import React, { useState } from "react";
import RecordNotFound from "./RecordNotFound";
import CustomBreadCrumsForLessons from "src/components/CustomBreadCrumsForLessons";
import { useNavigate, useParams } from "react-router-dom";
import { all_categiry_data_with_program_api_v1 } from "src/DAL/Programmes/Programmes";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Box, Card, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { s3baseUrl } from "../../../config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

export default function ProgrammesCategoryListParent() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [programmesData, setProgrammesData] = useState({});
  const [grandParentCategory, setGrandParentCategory] = useState({});
  const [parentCategoryObject, setparentCategoryObject] = useState({});
  const [categoryData, setCategoryData] = useState([]);

  const get_programmes_categiry_list = async () => {
    setIsLoading(true);
    const result = await all_categiry_data_with_program_api_v1(
      params?.slug,
      params?.parent_slug
    );
    if (result.code === 200) {
      if (result?.program?.program_lock_status === true) {
        navigate(`/programmes`);
      }
      setCategoryData(result);
      setProgrammesData(result.program);
      setGrandParentCategory(result.grandParentCategoryObject);
      setparentCategoryObject(result.parentCategoryObject);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
      setIsLoading(false);
    }
  };

  const handleChangeCategory = (value) => {
    navigate(
      `/programmes/${params?.slug}/${params?.grand_slug}/${params?.parent_slug}/${value?.category_slug}`
    );
  };

  const handleChangeLesson = (value) => {
    navigate(
      `/programmes/${params?.slug}/${params?.grand_slug}/${params?.parent_slug}/category-child/${value?.lesson_slug}`
    );
  };

  const handleChangeprogramme = () => {
    navigate(`/programmes`);
  };

  const handleChangeGrand = () => {
    navigate(`/programmes/${params?.slug}`);
  };

  const handleChangeParent = () => {
    navigate(`/programmes/${params?.slug}/${params?.grand_slug}`);
  };

  let breadCrumbMenu = [
    {
      title: "Programmes",
      onClick: handleChangeprogramme,
      active: false,
    },
    {
      title: programmesData?.title,
      onClick: handleChangeGrand,
      active: false,
    },
    {
      title: grandParentCategory?.category_name,
      onClick: handleChangeParent,
      active: false,
    },
    {
      title: parentCategoryObject?.category_name,
      active: true,
    },
  ];

  useEffect(() => {
    get_programmes_categiry_list();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="row">
      <div className="col-12 mt-2 mb-3">
        <CustomBreadCrumsForLessons breadCrumbMenu={breadCrumbMenu} />
      </div>

      {categoryData?.category?.length > 0 && (
        <>
          <div className="col-12 mt-2">
            <h2>Category</h2>
          </div>
          {categoryData?.category.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 mt-4" key={index}>
                <div
                  onClick={() => {
                    handleChangeCategory(item);
                  }}
                  className="h-100"
                >
                  <Card
                    className="programm-card h-100 secondary-background"
                    sx={{ cursor: "pointer", position: "relative" }}
                  >
                    <Box sx={{ position: "relative", cursor: "pointer" }}>
                      <ProgrammesImgStyle
                        alt={item?.category_name}
                        src={s3baseUrl + item?.category_image?.thumbnail_1}
                      />
                    </Box>
                    <Stack spacing={2} className="programme-content">
                      <h3>{item?.category_name}</h3>
                    </Stack>
                  </Card>
                </div>
              </div>
            );
          })}
        </>
      )}

      {categoryData?.lesson?.length > 0 && (
        <>
          <div
            className={`${
              categoryData?.category?.length > 0
                ? "col-12 mt-5"
                : "col-12 mt-2 "
            }`}
          >
            <h2>Lesson</h2>
          </div>
          {categoryData?.lesson.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 mt-4" key={index}>
                <div
                  onClick={() => {
                    handleChangeLesson(item);
                  }}
                  className="h-100"
                >
                  <Card
                    className="programm-card h-100 secondary-background"
                    sx={{ cursor: "pointer", position: "relative" }}
                  >
                    <Box sx={{ position: "relative", cursor: "pointer" }}>
                      <ProgrammesImgStyle
                        alt={item?.title}
                        src={s3baseUrl + item?.lesson_images?.thumbnail_1}
                      />
                    </Box>
                    <Stack spacing={2} className="programme-content">
                      <h3> {item?.title}</h3>
                    </Stack>
                  </Card>
                </div>
              </div>
            );
          })}
        </>
      )}
      {categoryData?.category?.length <= 0 &&
        categoryData?.lesson?.length <= 0 && <RecordNotFound title="Data" />}
    </div>
  );
}
