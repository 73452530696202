import React, { useEffect } from "react";
import "react-credit-cards/es/styles-compiled.css";
import ShowCard from "./ShowCardNew";
import { get_default_card } from "src/DAL/Payment/Payment";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useState } from "react";
import StripeCardChange from "./CardProvider";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const PaymentForm = () => {
  const classes = useStyles();
  const { userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [inputs, setInputs] = React.useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    type: "",
  });

  const getCardsDetail = async () => {
    const result = await get_default_card();
    if (result.code == 200) {
      if (result.stripe_card.last4) {
        let month =
          result.stripe_card.exp_month < 10
            ? "0" + result.stripe_card.exp_month
            : result.stripe_card.exp_month;
        setInputs((inputs) => ({
          ...inputs,
          ["cvc"]: "***",
          ["expiry"]: month + "/" + result.stripe_card.exp_year,
          ["name"]: result.stripe_card.name
            ? result.stripe_card.name
            : userInfo.first_name,
          ["number"]: "************" + result.stripe_card.last4,
          ["type"]: result.stripe_card.brand,
        }));
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCardsDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      {inputs.number ? (
        <ShowCard inputs={inputs} setInputs={setInputs} />
      ) : (
        <StripeCardChange inputs={inputs} setInputs={setInputs} />
      )}
    </div>
  );
};
export default PaymentForm;
