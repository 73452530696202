import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography } from "@mui/material";
import { login } from "../DAL/Login/Login";
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import { s3baseUrl } from "../config/config";

import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  _get_is_seen_video_localStorage,
  get_page_content_localStorage,
} from "src/DAL/localStorage/localStorage";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { handleUserInfo, projectInfo } = useContentSetting();
  const content_data = get_page_content_localStorage();
  const is_seen_video = _get_is_seen_video_localStorage();

  const handleSubmitLogin = async (data) => {
    setLoading(true);
    const result = await login(data);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result?.member?.consultant)
      );
      handleUserInfo(result?.member);
      localStorage.setItem("token", result.token);
      if (is_seen_video && is_seen_video === "Yes") {
      } else {
        localStorage.setItem("is_seen_video", "No");
      }
      navigate("/dashboard", { replace: true });
      setLoading(false);
    } else {
      enqueueSnackbar("Invalid Credentials", { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <div
      className="whole-background"
      style={{
        backgroundImage: `url(${s3baseUrl + content_data?.login_page_image})`,
      }}
    >
      <RootStyle>
        <div className="row w-100">
          <div className="col-12 col-lg-6"></div>
          <div className="col-12 col-lg-6">
            <Container maxWidth="sm">
              <ContentStyle>
                <Stack sx={{ mb: 5 }}>
                  <Typography variant="h4" gutterBottom>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: projectInfo?.welcome_login_page_text,
                      }}
                    ></div>
                  </Typography>
                </Stack>

                <LoginForm
                  isLoading={loading}
                  onhandleLogin={handleSubmitLogin}
                />

                <MHidden width="smUp">
                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Don’t have an account?&nbsp;
                  </Typography>
                </MHidden>
              </ContentStyle>
            </Container>
          </div>
        </div>
      </RootStyle>
    </div>
  );
}
