import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Typography, Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import ProgrammRecording from "../../components/_dashboard/programmes/ProgrammeRecording";
import ResourcesCard from "../../components/_dashboard/programmes/ResourcesCard";
import {
  lessonDetail,
  markelessonCompleted,
} from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import { ReactVideoPlayer } from "src/components";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import CustomPopover from "../../components/CustomPopover";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import {
  AddNote,
  EditNote,
  DeleteNote,
} from "../..//DAL/Programmes/Lessons/Notes/Notes";
import CustomConfirmation from "src/components/CustomConfirmation";
import { show_proper_words } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Icon } from "@iconify/react";
import CustomBreadCrums from "src/components/CustomBreadCrums";

const ProgrammesImgStyle = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LessonsDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const [lessonsDetail, setLessonsDetail] = useState({});
  const [resourcesList, setResourcesList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [nextLesson, setNextLesson] = useState({});
  const [previousLesson, setPreviousLesson] = useState({});
  const [notesList, setNotesList] = useState([]);
  const [inputLessonNote, setInputLessonNote] = useState("");
  const [completeLessonSlug, setCompleteLessonSlug] = useState("");
  const [noteId, setNoteId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMarkComplete, setOpenMarkComplete] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState({});
  const { handleVideoList } = useContentSetting();
  const [showButtons, setShowButtons] = useState({
    addButton: true,
    editButton: false,
  });
  const [value, setValue] = useState(0);
  const [videoProgressData, setVideoProgressData] = useState([]);

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getLesonDetail = async () => {
    setIsLoading(true);
    const result = await lessonDetail(params.detail_slug);
    if (result.code === 200) {
      if (result?.program?.program_lock_status === true) {
        navigate(`/programmes`);
      }
      let data = [
        {
          recording_id: result.lesson?._id,
          video_duration: result.lesson?.video_duration,
          total_video_duration: result.lesson?.total_video_duration,
          is_complete: result.lesson?.is_complete,
        },
      ];
      setVideoProgressData(data);
      handleVideoList(data);
      localStorage.setItem("video_list", JSON.stringify(data));
      localStorage.setItem("video_type", "lesson");
      setLessonsDetail(result.lesson);
      setResourcesList(result.document_list);
      setRecordingList(result.recording_list);
      setNotesList(result.lesson_note);
      setNextLesson(result.next_lesson);
      setPreviousLesson(result.previous_lesson);
      setCategoryDetail(result.category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
      setIsLoading(false);
    }
  };

  const handleMarkComplete = (lesson_slug) => {
    setCompleteLessonSlug(lesson_slug);
    setOpenMarkComplete(true);
  };

  const mrakCompleted = async () => {
    setOpenMarkComplete(false);
    setIsLoading(true);
    const result = await markelessonCompleted(completeLessonSlug);
    if (result.code === 200) {
      getLesonDetail();
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  //Changing Textarea values with onchange
  const handleNoteChange = (event) => {
    const value = event.target.value;
    setInputLessonNote(value);
  };
  //Adding Note
  const handleSubmit = async (e) => {
    setIsLoadingNotes(true);
    e.preventDefault();
    let postData = {
      lesson_note: inputLessonNote,
      lesson_slug: params?.detail_slug,
      program_slug: params?.slug,
    };
    setInputLessonNote("");
    const result = await AddNote(postData);
    if (result.code === 200) {
      setInputLessonNote("");
      setNotesList((prevNotesList) => [...prevNotesList, result.lesson_note]);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setTimeout(() => {
      setIsLoadingNotes(false);
    }, 500);
  };

  const handleEdit = (note) => {
    setInputLessonNote(note.lesson_note);
    setNoteId(note._id);
    setShowButtons({
      addButton: false,
      editButton: true,
    });

    const notes = document.getElementById("notes-input");
    setTimeout(() => {
      notes.scrollIntoView();
      notes.focus();
    }, 500);
  };

  const handleCancelUpdate = () => {
    setInputLessonNote("");
    setNoteId("");
    setShowButtons({
      addButton: true,
      editButton: false,
    });
  };

  const handleAgreeDelete = (note) => {
    setNoteId(note._id);
    setOpenDelete(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoadingNotes(true);
    const formData = new FormData();
    formData.append("lesson_note", inputLessonNote);
    const result = await EditNote(noteId, formData);
    if (result.code === 200) {
      setNotesList((prevNotesList) => {
        return prevNotesList.map((note) =>
          note._id === noteId ? result.lesson_note : note
        );
      });
      setInputLessonNote("");
      setNoteId(0);
      setShowButtons({
        addButton: true,
        editButton: false,
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoadingNotes(false);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteNote(noteId);
    if (result.code === 200) {
      setNotesList((prevNotesList) =>
        prevNotesList.filter((note) => note._id !== noteId)
      );
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const showPreviouLesson = () => {
    navigate(
      `/programmes/${params?.slug}/${params?.grand_slug}/${params?.parent_slug}/${params?.child_slug}/${previousLesson.lesson_slug}`
    );
  };
  const showNextLesson = () => {
    navigate(
      `/programmes/${params?.slug}/${params?.grand_slug}/${params?.parent_slug}/${params?.child_slug}/${nextLesson.lesson_slug}`
    );
  };

  const handleClick = (item) => {
    navigate(item.navigation);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: categoryDetail?.program?.title,
      navigation: `/programmes/${params?.slug}`,
      handleClick: handleClick,
      active: false,
    },
    {
      title: categoryDetail?.grandParentCategory?.category_name,
      navigation: `/programmes/${params?.slug}/${params?.grand_slug}`,
      handleClick: handleClick,
      active: false,
    },
    {
      title: categoryDetail?.parentCategory?.category_name,
      navigation: `/programmes/${params?.slug}/${params?.grand_slug}/${params?.parent_slug}`,
      handleClick: handleClick,
      active: false,
    },
    {
      title: categoryDetail?.category_name,
      navigation: `/programmes/${params?.slug}/${params?.grand_slug}/${params?.parent_slug}/${params?.child_slug}`,
      handleClick: handleClick,
      active: false,
    },
    {
      title: lessonsDetail?.title,
      active: true,
    },
  ];

  useEffect(() => {
    getLesonDetail();
  }, [params?.detail_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mt-3  justify-content-center align-items-center  ">
        <div className="col-12">
          <CustomBreadCrums breadCrumbMenu={breadCrumbMenu} />
          {lessonsDetail.lesson_completion_status === true ? (
            <button
              className="small-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              Completed
            </button>
          ) : (
            <button
              className="small-contained-button float-end mt-1"
              onClick={() => {
                handleMarkComplete(lessonsDetail.lesson_slug);
              }}
            >
              Mark Complete
            </button>
          )}
        </div>
      </div>
      <div className="row media-margin mt-3">
        <div className="col-12">
          {lessonsDetail?.landing_lesson_video_url !== "undefined" &&
          lessonsDetail?.landing_lesson_video_url ? (
            <ReactVideoPlayer
              url={lessonsDetail?.landing_lesson_video_url}
              type={"lesson"}
              value={lessonsDetail}
              setVideoProgressData={setVideoProgressData}
              videoProgressData={videoProgressData}
            />
          ) : (
            <></>
          )}
          {/* <ProgrammesImgStyle
            src={s3baseUrl + lessonsDetail?.lesson_images?.thumbnail_1}
          /> */}
        </div>
        <div className="col-12 text-end mb-2">
          {lessonsDetail?.is_complete ? (
            <Icon
              icon="mdi:tick-circle"
              width={30}
              height={30}
              color="primary"
            />
          ) : (
            <></>
          )}
        </div>
        <div className="col-12 section-space set-image-center">
          <div
            dangerouslySetInnerHTML={{
              __html: lessonsDetail?.detailed_description,
            }}
          ></div>
        </div>
        <div className="col-12 section-space">
          {Object.entries(previousLesson).length > 0 && (
            <button
              className="small-contained-button"
              onClick={showPreviouLesson}
            >
              {"<<"} Previous
            </button>
          )}
          {Object.entries(nextLesson).length > 0 && (
            <button
              className="small-contained-button float-end"
              onClick={showNextLesson}
            >
              Next {">>"}
            </button>
          )}
        </div>
        {lessonsDetail?.audio_file && (
          <div className="col-12 mt-3 text-center">
            <div>
              <audio
                controlsList="nodownload"
                className="w-100"
                src={s3baseUrl + lessonsDetail?.audio_file}
                controls
              />
            </div>
          </div>
        )}
      </div>
      {resourcesList.length > 0 && (
        <div className="col-12 section-space">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {/* <Tab label="Recordings" {...a11yProps(0)} /> */}
                <Tab label="Resources" {...a11yProps(0)} />
                {/* <Tab label="Notes" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            {/* <TabPanel value={value} index={0}>
            <div className="row">
              {recordingList.length > 0 ? (
                recordingList.map((recording, i) => (
                  <div className="col-12 mt-3">
                    <ProgrammRecording
                      recording={recording}
                      programSlug={lessonsDetail.program[0]._id.program_slug}
                      lessonSlug={params.detail_slug}
                    />
                  </div>
                ))
              ) : (
                <RecordNotFound title="Recording" />
              )}
            </div>
          </TabPanel> */}
            <TabPanel value={value} index={0}>
              <div className="row">
                {resourcesList.map((resource, i) => (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3 d-flex">
                    <ResourcesCard imageLink={imageLinks} resource={resource} />
                  </div>
                ))}
                {/* {resourcesList.length > 0 ? (
               
              ) : (
                <RecordNotFound title="Resource" />
              )} */}
              </div>
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
            <div className="row">
              {notesList?.map((note, index) => (
                <div className="col-12 section-space" key={note._id}>
                  <div className="card">
                    <div className="card-body notes-input set-display-inline">
                      <CustomPopover menu={MENU_OPTIONS} data={note} />
                      <p className="mb-0 normal-text">
                        {htmlDecode(note.lesson_note)}
                      </p>
                      <p className="lesson-notes-title mt-2 mb-1 date-color">
                        {note.lesson_note_date}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <form
                  onSubmit={showButtons.addButton ? handleSubmit : handleUpdate}
                >
                  <span
                    htmlFor="exampleFormControlInput1"
                    className="form-label-lesson lesson heading"
                  >
                    Lesson Notes:
                  </span>
                  <textarea
                    required
                    rows="5"
                    id="notes-input"
                    className="form-control text-area-task notes mt-2"
                    value={inputLessonNote}
                    onChange={handleNoteChange}
                  />
                  {showButtons.addButton === true && (
                    <button
                      type="submit"
                      className="mt-2 float-end small-contained-button"
                      disabled={isLoadingNotes}
                    >
                      {isLoadingNotes ? "Saving..." : "Save"}
                    </button>
                  )}
                  {showButtons.editButton === true && (
                    <div className="d-flex justify-content-end">
                      <button
                        className="mt-2 me-2 small-contained-button"
                        onClick={handleCancelUpdate}
                      >
                        Cancel
                      </button>
                      <button
                        className="mt-2 small-contained-button"
                        type="submit"
                        disabled={isLoadingNotes}
                      >
                        {isLoadingNotes ? "Updating..." : "Update"}
                      </button>
                    </div>
                  )}
                  <CustomConfirmation
                    open={openDelete}
                    setOpen={setOpenDelete}
                    title={"Are you sure you want to delete this note?"}
                    handleAgree={handleDelete}
                  />
                </form>
              </div>
            </div>
          </TabPanel> */}
          </Box>
        </div>
      )}
      <CustomConfirmation
        open={openMarkComplete}
        setOpen={setOpenMarkComplete}
        title={"Are you sure you want to mark this lesson as completed?"}
        handleAgree={mrakCompleted}
      />
    </div>
  );
}

export default LessonsDetail;
