import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MomentUtils from "@date-io/moment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  EditEventApi,
  update_event_iteration_api,
} from "src/DAL/Calender/Calender";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  TIME_ZONE_CONVERSION,
  get_date_with_user_time_zone,
} from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import PerformActionOn from "src/components/FormsDialog/PerformActionOn";
import GuestUserInfo from "./GuestUserInfo";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));
export default function EditEvent({ editValues, dataList, handleCloseDrawer }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const { userInfo, adminTimeZone } = useContentSetting();
  const [openPopup, setOpenPopup] = useState(false);
  const [oldTime, setOldTime] = useState({});
  const USER_DATA = [userInfo];

  // const [state, setState] = useState({
  //   eventDescription: "",
  //   eventTitle: "",
  //   eventSlug: "",
  //   eventColor: "black",
  //   startTime: "00:00",
  //   endTime: "00:00",
  //   itrationNumber: 1,
  //   no_of_days_to_repeat_after: 0,
  //   recurringType: "daily",
  //   startDate: new Date(),
  // });
  const [state, setState] = useState({
    description: "",
    event_title: "",
    event_color: "#000",
    recurring_type: "daily",
    notify_before_unit: "minutes",
    notify_before_time: 30,
    start_date: dayjs(new Date()).$d,
    weekday: [new Date().getDay()],
    end_date: dayjs(new Date()).$d,
    start_time: dayjs(new Date()).$d,
    end_time: dayjs(new Date()).$d,
    status: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (name, event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: event.$d,
      };
    });
  };

  const updateIteration = async (value) => {
    setOpenPopup(false);
    setIsLoading(true);
    const formData = {
      title: state.event_title,
      color: state.event_color,
      description: shortDescriptionCk,
      iteration_id: state._id,
      start_date: moment(state.start_date).format("YYYY-MM-DD"),
      end_date: moment(state.end_date).format("YYYY-MM-DD"),
      start_time: moment(state.start_time).format("HH:mm"),
      end_time: moment(state.end_time).format("HH:mm"),
      update_type: value,
    };
    const result = await update_event_iteration_api(formData, state.event_slug);
    if (result.code === 200) {
      dataList();
      handleCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      state.event_title !== editValues.event_title ||
      state.start_time.getTime() !== oldTime.start_time.getTime() ||
      state.end_time.getTime() !== oldTime.end_time.getTime()
    ) {
      setOpenPopup(true);
    } else {
      updateIteration("current");
    }
  };
  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };
  useEffect(() => {
    setState({
      ...editValues,
      start_date: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_date: dayjs(get_start_end_time(editValues.end_date_time)).$d,
      start_time: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_time: dayjs(get_start_end_time(editValues.end_date_time)).$d,
    });
    setShortDescriptionCk(editValues.description);
    setOldTime({
      start_time: dayjs(get_start_end_time(editValues.start_date_time)).$d,
      end_time: dayjs(get_start_end_time(editValues.end_date_time)).$d,
    });
    //  let notify_before = [];
    //  if (editValues.notify_before && editValues.notify_before.length > 0) {
    //    notify_before = editValues.notify_before;
    //  }
    //  setNotificationArray(notify_before);
  }, []);

  return (
    <>
      {" "}
      <div className="container ">
        <form className="row" onSubmit={handleSubmit}>
          <div className="text-end mb-4">
            <button
              className="small-contained-button event-submit-button"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <TextField
              className="mt-3 w-100"
              id="outlined-basic"
              label="Title"
              variant="outlined"
              name="event_title"
              value={state.event_title}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div
            className={`col-12 col-md-6 ${
              state.recurring_type == "weekly" ? "col-lg-3" : "col-lg-4"
            }`}
          >
            <TextField
              id="event_color"
              type="color"
              label="Color"
              name="event_color"
              className="mt-3 w-100"
              variant="outlined"
              required={true}
              value={state.event_color}
              onChange={handleChange}
            />
          </div>
          <div
            className={`col-12 col-md-6 ${
              state.recurring_type == "weekly" ? "col-lg-2" : "col-lg-4"
            }`}
          >
            <FormControl variant="outlined" className="mt-3 w-100">
              <InputLabel id="demo-simple-select-outlined-label">
                Recurring Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.recurring_type}
                onChange={(e) => handleChange(e)}
                label="recurring Type"
                name="recurring_type"
                className="inputs-fields svg-color"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
                sx={{
                  color: get_root_value("--input-text-color"),
                }}
                disabled
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </FormControl>
          </div>
          {state.recurring_type == "weekly" && (
            <div className={`col-12 col-md-6 col-lg-3`}>
              <FormControl variant="outlined" className="mt-3 w-100">
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Day
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.weekday}
                  onChange={(e) => handleChange(e)}
                  label="Select Day"
                  name="weekday"
                  className="inputs-fields svg-color"
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                    },
                  }}
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                  disabled
                >
                  <MenuItem value={0}>Sunday</MenuItem>
                  <MenuItem value={1}>Monday</MenuItem>
                  <MenuItem value={2}>Tuesday</MenuItem>
                  <MenuItem value={3}>Wednesday</MenuItem>
                  <MenuItem value={4}>Thursday</MenuItem>
                  <MenuItem value={5}>Friday</MenuItem>
                  <MenuItem value={6}>Saturday</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                name="start_date"
                inputFormat="DD-MM-YYYY"
                value={state.start_date}
                format="DD-MM-YYYY"
                className="mt-3 w-100"
                onChange={(e) => handleChangeDate("start_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Start Time"
                name="start_time"
                value={state.start_time}
                sx={{ color: "#fff" }}
                className="mt-3 w-100"
                onChange={(e) => handleChangeDate("start_time", e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="End Date"
                name="end_date"
                inputFormat="DD-MM-YYYY"
                value={state.end_date}
                format="DD-MM-YYYY"
                className="mt-3 w-100"
                onChange={(e) => handleChangeDate("end_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="End Time"
                name="end_time"
                value={state.end_time}
                sx={{ color: "#fff" }}
                className="mt-3 w-100"
                onChange={(e) => handleChangeDate("end_time", e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <div className="col-12 col-md-9 mt-3 mt-4">
            <h5 className="sale-page-title ms-0">Event Detail</h5>
            <hr />
            <h4>Event Description </h4>
            <TinyEditor
              setDetailDescription={setShortDescriptionCk}
              detailDescriptionCk={shortDescriptionCk}
            />
          </div>
          <div className="col-12 col-md-3 mt-4">
            <h5 className="sale-page-title ms-0">Guests</h5>
            <hr />
            <GuestUserInfo data={USER_DATA} />
          </div>
          {/* <div className="text-end mt-3 ">
            <button className="submit-button" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div> */}
        </form>
      </div>
      <PerformActionOn
        open={openPopup}
        setOpen={setOpenPopup}
        title={"Perform this action On?"}
        handleAgree={updateIteration}
      />
    </>
  );
}
