import React, { useState, useEffect } from "react";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import AddTeacher from "./AddTeacher";
import EditTeacher from "./EditTeacher";
import UserListToolbar from "src/components/CustomTable/UserListToolbar";
import UserListHead from "src/components/CustomTable/UserListHead";
import SearchNotFound from "src/components/SearchNotFound";
import CustomConfirmation from "src/components/CustomConfirmation";
import CustomPopover from "src/components/CustomPopover";
import { DeleteTeachers, TeachersListing } from "src/DAL/Teachers/Teachers";

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const userList = [
  { id: 1, name: "rida", email: "ridagmail" },
  { id: 2, name: "rida", email: "ridagmail" },
  { id: 3, name: "khadija", email: "khadija" },
  { id: 4, name: "humna", email: "humna" },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function Teachers() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [teachersData, setTeachersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const { contentSettingData, userInfo } = useContentSetting();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [orderBy, setOrderBy] = useState("number");

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  console.log(userInfo, "userInfouserInfo");
  const getTeachersListing = async () => {
    const result = await TeachersListing(userInfo?._id);
    if (result.code === 200) {
      setTeachersData(result.teacher);
      setTotalPages(result.teacher.length / rowsPerPage);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  //Getting Note In textarea
  const handleEdit = (value) => {
    console.log(value, "value");
    setEditValues(value);
    setEditDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    console.log(value, "value");
    setDeleteData(value._id);
    setOpenDelete(true);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);
    let myArray = [];
    const result = await DeleteTeachers(deleteData);
    if (result.code === 200) {
      myArray = teachersData.filter(function (obj) {
        return obj._id !== deleteData;
      });
      setTeachersData(myArray);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTeachersListing();
  }, []);

  const handleDetailPage = (pinterestSlug) => {
    navigate(`/memories/memories_detail/${pinterestSlug}`);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - teachersData.length) : 0;

  const filteredUsers = applySortFilter(
    teachersData,
    getComparator(order, orderBy),
    filterName
  );
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const isUserNotFound = filteredUsers.length === 0;

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {contentSettingData && contentSettingData.teachers_heading ? (
              <>
                <div
                  className="dashboard_description"
                  dangerouslySetInnerHTML={{
                    __html: contentSettingData.teachers_heading,
                  }}></div>
                {/* {userInfo?.payment_info && (
                  <>
                    {userInfo.payment_info?.plan_title ? (
                      <>
                        <p>{`Max Number of Allowed Teachers in School is ${userInfo?.payment_info?.max_teacher_limit} and Your Purchased Plan is (${userInfo?.payment_info?.plan_title})`}</p>
                      </>
                    ) : (
                      <>
                        <p>{`Max Number of Allowed Teachers in School is ${userInfo?.payment_info?.max_teacher_limit}`}</p>
                      </>
                    )}
                  </>
                )} */}
                <p>
                  Teachers name is limited to the number of your subscription.
                </p>
              </>
            ) : (
              <>
                <h2 className="teachers-heading">Teachers</h2>
                {/* {userInfo?.payment_info && (
                  <>
                    {userInfo.payment_info?.plan_title ? (
                      <>
                        <p>{`Max Number of Allowed Teachers in School is ${userInfo?.payment_info?.max_teacher_limit} and Your Purchased Plan is (${userInfo?.payment_info?.plan_title})`}</p>
                      </>
                    ) : (
                      <>
                        <p>{`Max Number of Allowed Teachers in School is ${userInfo?.payment_info?.max_teacher_limit}`}</p>
                      </>
                    )}
                  </>
                )} */}
                <p>
                  Teachers name is limited to the number of your subscription.
                </p>
              </>
            )}
          </div>
          <div className="col-12">
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleOpenDrawer}>
              Add Teacher
            </button>
          </div>
        </div>
        <Card style={{ overflowX: "auto" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column align-items-start">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={teachersData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages + 1}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePages}
                className="pagination-style"
              />
            </div>
            <div className="d-flex align-items-center">
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </div>
          </div>

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={teachersData.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const { id, name, email } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}>
                        <TableCell align="left">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {email}
                            </Typography>
                          </Stack>
                        </TableCell>{" "}
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}>
                            <CustomPopover menu={MENU_OPTIONS} data={row} />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={teachersData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>{" "}
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit Teacher"
        componentToPassDown={
          <EditTeacher
            dataList={getTeachersListing}
            editData={editValues}
            setEditValues={setEditValues}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Add Teacher"
        componentToPassDown={
          <AddTeacher
            dataList={getTeachersListing}
            // setTeachersData={setTeachersData}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}
