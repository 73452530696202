import React, { useContext, useEffect, useState } from "react";
import {
  // get_consultant_data_localStorage,
  get_project_info_localStorage,
  _get_admin_time_zone_localStorage,
  _get_client_dashboard_setting_localStorage,
  _get_content_setting_localStorage,
  _get_home_event_localStorage,
  _get_navbar_list_localStorage,
  _get_testmonial_localStorage,
  _get_user_from_localStorage,
  _get_videos_list_localStorage,
} from "src/DAL/localStorage/localStorage";

const CreateContentSetting = React.createContext();
const content_setting = _get_content_setting_localStorage();
const client_dashboard_setting = _get_client_dashboard_setting_localStorage();
const get_testmonial_localStorage = _get_testmonial_localStorage();
const get_home_event_localStorage = _get_home_event_localStorage();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_project_info = get_project_info_localStorage();
const _get_user_info = _get_user_from_localStorage();
// const _get_consultant_data = get_consultant_data_localStorage();
const get_navbar_list = _get_navbar_list_localStorage();
const get_videos_list = _get_videos_list_localStorage();
export const useContentSetting = () => useContext(CreateContentSetting);

export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [contentSettingData, setContentSettingData] = useState(content_setting);
  const [projectInfo, setProjectInfo] = useState(get_project_info);
  const [userInfo, setUserInfo] = useState(_get_user_info);
  // const [consultantInfo, setConsultantInfo] = useState(_get_consultant_data);
  const [navbarList, setNavbarList] = useState(get_navbar_list);
  const [videoList, setVideoList] = useState(get_videos_list);
  const [stripeKey, setStripeKey] = useState("");
  const [dashboardEventData, setDashboardEventData] = useState(
    get_home_event_localStorage
  );
  const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
    get_testmonial_localStorage
  );
  const [dashboardSettingData, setDashboardSettingData] = useState(
    client_dashboard_setting
  );

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleContentSettingData = (val) => {
    setContentSettingData(val);
  };
  const handleDashboardSettingData = (val) => {
    setDashboardSettingData(val);
  };
  const handleDashboardTestimonialData = (val) => {
    setDashboardTestimonialData(val);
  };
  const handleDashboardEventData = (val) => {
    setDashboardEventData(val);
  };
  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };
  const handleUserInfo = (val) => {
    setUserInfo(val);
  };
  // const handleConsultantInfo = (val) => {
  //   setConsultantInfo(val);
  // };

  const handleProjectInfo = (val) => {
    setProjectInfo(val);
  };

  const handleNavbarList = (val) => {
    setNavbarList(val);
  };
  const handleVideoList = (val) => {
    setVideoList(val);
  };
  const handleStripeKey = (val) => {
    setStripeKey(val);
  };

  const collection = {
    adminTimeZone,
    navbarList,
    videoList,
    // consultantInfo,
    userInfo,
    stripeKey,
    projectInfo,
    dashboardEventData,
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    setUserInfo,
    handleProjectInfo,
    handleAdminTimeZone,
    handleUserInfo,
    // handleConsultantInfo,
    handleStripeKey,
    handleNavbarList,
    handleVideoList,
  };

  useEffect(() => {
    localStorage.setItem(`user_data`, JSON.stringify(userInfo));
  }, [userInfo]);

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
