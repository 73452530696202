import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

export default function CustomBreadCrumsForLessons({ breadCrumbMenu }) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {breadCrumbMenu.map((item, index) => {
          return (
            <Link
              underline="hover"
              color={item.active == true ? "#ea7423" : "black"}
              onClick={item.onClick}
            >
              <span style={{ cursor: "pointer" }}>{item?.title}</span>
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
