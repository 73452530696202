import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box, Card, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
import { s3baseUrl } from "../../../config/config";

// ----------------------------------------------------------------------

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

ProgrammesCardFirstIndex.propTypes = {
  product: PropTypes.object,
};

export default function ProgrammesCardFirstIndex({ programm }) {
  const navigate = useNavigate();
  const {
    title,
    program_slug,
    short_description,
    program_images,
    no_of_lesson,
    total_lesson_duration,
    program_lock_status,
  } = programm;

  return (
    <Card
      className="programm-card h-100 secondary-background"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        if (program_lock_status === false) {
          navigate(`/programmes/${program_slug}`, {
            state: programm,
          });
        }
      }}
    >
      <Box sx={{ position: "relative", cursor: "pointer" }}>
        {program_lock_status === true ? (
          <button
            variant="contained"
            className="small-contained-button program-lock-icon"
            style={{
              zIndex: 9,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "14px",
              position: "absolute",
              padding: "10px 20px",
              width: "55%",
            }}
          >
            <LockIcon className="lock-icon-color" />
            &nbsp;&nbsp;Unlock Access
          </button>
        ) : (
          ""
        )}
        <ProgrammesImgStyle
          alt={title}
          src={s3baseUrl + program_images.thumbnail_1}
        />
      </Box>

      <Stack spacing={2} className="programme-content ">
        <h3>{title}</h3>
        <p className="programme-card-desc">{short_description}</p>
        <div className="card-button">
          <p className="programme-duration">
            <span>
              {no_of_lesson} Lessons &nbsp; | &nbsp; {total_lesson_duration}
            </span>
          </p>
        </div>
      </Stack>
    </Card>
  );
}
