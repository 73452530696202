import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import {
  AffirmationIcons,
  GoalStatmentIcons,
  GratitudeIcons,
  QuotesIcons,
} from "src/assets";

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = (navbarList, consultantInfo, userInfo) => {
  console.log(navbarList, "navbarListnavbarListnavbarListnavbarList");
  let sidebarMenus = [];
  sidebarMenus.push({
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon(pieChart2Fill),
    matches: ["/dashboard", "/post-detail", "/video-detail"],
  });
  if (navbarList && navbarList.teachers) {
    sidebarMenus.push({
      title: "Teachers",
      path: "/teachers",
      icon: getIcon("gridicons:multiple-users"),
    });
  }
  if (navbarList && navbarList.programmes) {
    sidebarMenus.push({
      title: "Programmes",
      path: "/programmes",
      icon: getIcon(barchartfill),
    });
  }

  if (navbarList && navbarList.calendar) {
    sidebarMenus.push({
      title: "calendar",
      path: "/calendar",
      icon: getIcon("uil:calender"),
    });
  }
  if (navbarList && navbarList.support_ticket) {
    sidebarMenus.push({
      title: "support tickets",
      path: "/support-tickets",
      icon: getIcon("ic:round-date-range"),
    });
  }

  return sidebarMenus;
};
export default sidebarConfig;
