// here we add all base urls and keys

import { get_project_info_localStorage } from "src/DAL/localStorage/localStorage";
import { appLogoIcon } from "src/assets";

export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const project_name = process.env.REACT_APP_PROJECT_NAME;
export const websiteBaseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;
export const api_key = process.env.REACT_APP_WEB_API_KEY;

const get_project_info = get_project_info_localStorage();

export const material_theme_palate = {
  lighter: "#d4bff2",
  light: "#ede7f6",
  main: "#ea7423",
  dark: "#198BA8",
  darker: "#198BA8",
  contrastText: "#fff",
};

export const OwnerName = () => {
  if (project_name == "core-ni") {
    return "";
  }
};

export const ownerName = OwnerName();
export const ownerCompleteName = OwnerName();

export const get_app_logo = () => {
  if (get_project_info && get_project_info.brand_logo) {
    return s3baseUrl + get_project_info.brand_logo;
  } else {
    return appLogoIcon;
  }
};

export const get_app_headers = () => {
  if (get_project_info && get_project_info.meta_title) {
    return {
      title: get_project_info.meta_title,
      description: get_project_info.meta_description,
      fav_icon: s3baseUrl + get_project_info.brand_favicon,
    };
  } else {
    return {
      title: "CORE Kids",
      description: "CORE Kids",
      fav_icon: appLogoIcon,
    };
  }
};
