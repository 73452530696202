import { useState } from "react";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ProgrammesList } from "../../components/_dashboard/programmes";
import { programmesListing } from "../../DAL/Programmes/Programmes";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ProgrammesCardFirstIndex from "src/components/_dashboard/programmes/ProgrammesCardFirstIndex";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Programmes() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [programmesData, setProgrammesData] = useState([]);
  const [firstProgrammeData, setFirstProgrammeData] = useState({});
  const { contentSettingData } = useContentSetting();

  const getProgrammesList = async () => {
    setIsLoading(true);
    const result = await programmesListing();
    if (result.code === 200) {
      // if (result.program.length > 0) {
      //   setFirstProgrammeData(result.program[0]);
      //   delete result.program[0];
      // }

      setProgrammesData(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProgrammesList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mt-3">
        {contentSettingData && contentSettingData.programs_description ? (
          <div
            className="dashboard_description"
            dangerouslySetInnerHTML={{
              __html: contentSettingData.programs_description,
            }}
          ></div>
        ) : (
          <h2>Programmes</h2>
        )}
        {programmesData.length > 0 ? (
          <>
            {/* <div className="row">
              <div className="col-12">
                <ProgrammesCardFirstIndex programm={firstProgrammeData} />
              </div>
            </div> */}
            <ProgrammesList programmes={programmesData} />
          </>
        ) : (
          <>
            <RecordNotFound title="Programmes" />
          </>
        )}
      </div>
    </div>
  );
}
