import { ReactVideoPlayer } from "src/components";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";

export default function DashboardApp() {
  const { contentSettingData, dashboardSettingData } = useContentSetting();
  const is_seen_video = _get_is_seen_video_localStorage();

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-12 dashboard_description">
          {contentSettingData && contentSettingData.dashboard_description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: contentSettingData.dashboard_description,
              }}
            ></div>
          ) : (
            <h2 className="dashboard-heading">
              {dashboardSettingData?.wellcome_text}
            </h2>
          )}
        </div>
      </div>

      <div className="row">
        {dashboardSettingData?.video_url ? (
          <span>
            {is_seen_video === "No" ? (
              <div className="col-sm-12 col-md-12 col-lg-12 my-5">
                <ReactVideoPlayer
                  url={dashboardSettingData?.video_url}
                  setting={dashboardSettingData}
                />
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: dashboardSettingData?.welcome_image,
                }}
              ></div>
            )}
          </span>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: dashboardSettingData?.welcome_image,
            }}
          ></div>
        )}
      </div>
    </div>
  );
}
