import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Box, Card, Stack, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { lesson_list_by_program_and_category_api } from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "../../config/config";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import { htmlDecode } from "src/utils/convertHtml";
import CustomBreadCrums from "src/components/CustomBreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

export default function LessonListing() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [lessonData, setLessonData] = useState([]);
  const [categoryDetail, setCategoryDetail] = useState({});

  const list_by_program_and_category = async () => {
    setIsLoading(true);
    const result = await lesson_list_by_program_and_category_api(
      params?.slug,
      params?.lesson_slug
    );

    if (result.code === 200) {
      if (result?.program?.program_lock_status === true) {
        navigate(`/programmes`);
      }
      setLessonData(result.lesson);
      setCategoryDetail(result.category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    list_by_program_and_category();
  }, []);

  const handleClick = (item) => {
    navigate(item.navigation, {
      state: {
        tab_index: item.tab_index,
        state_data: state,
      },
    });
  };

  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },
    {
      title: categoryDetail?.program?.title,
      navigation: `/programmes/${params?.slug}`,
      active: false,
      handleClick: handleClick,
      tab_index: 0,
    },
    {
      title: categoryDetail?.grandParentCategory?.category_name,
      navigation: `/programmes/${params?.slug}`,
      active: false,
      handleClick: handleClick,
      tab_index: 1,
    },
    {
      title: categoryDetail?.parentCategory?.category_name,
      navigation: `/programmes/${params?.slug}`,
      active: false,
      handleClick: handleClick,
      tab_index: 2,
    },
    {
      title: categoryDetail?.category_name,
      active: true,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12 mt-2 mb-3">
            <CustomBreadCrums breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="row">
          {lessonData.length > 0 ? (
            lessonData.map((lesson, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                <Card
                  className="programm-card h-100"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      `/programmes/${params?.slug}/${params?.lesson_slug}/${lesson.lesson_slug}`,
                      {
                        state: {
                          state_data: state && state,
                        },
                      }
                    );
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <ProgrammesImgStyle
                      alt="lessons"
                      src={s3baseUrl + lesson?.lesson_images?.thumbnail_1}
                    />
                  </Box>

                  <Stack spacing={2} className="lesson-content">
                    <h3>{htmlDecode(lesson.title)}</h3>
                    <p className="programme-card-desc">
                      {htmlDecode(lesson?.short_description)}
                    </p>
                  </Stack>
                </Card>
              </div>
            ))
          ) : (
            <RecordNotFound title="Data" />
          )}
        </div>
      </div>
    </>
  );
}
