import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ChangeCreditCard from "./ChangeCreditCard";
import ChangeDefaultCreditCard from "./ChangeCreditCard";

export default function StripeCardChange({}) {
  const { stripeKey } = useContentSetting();
  return (
    <Elements stripe={loadStripe(stripeKey)}>
      <ChangeDefaultCreditCard />
    </Elements>
  );
}
