import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

export default function CustomBreadCrums({ breadCrumbMenu }) {
  const navigate = useNavigate();
  function handleClick(event, value) {
    event.preventDefault();
    navigate(value);
  }
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {breadCrumbMenu.map((item, index) => {
          return (
            <Link
              underline="hover"
              color={item.active == true ? "#ea7423" : "black"}
              onClick={() =>
                item.handleClick
                  ? item.handleClick(item, index)
                  : navigate(item?.navigation)
              }
            >
              <span style={{ cursor: "pointer" }}>{item?.title}</span>
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
