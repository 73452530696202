const _get_user_from_localStorage = () => {
  const user_profile = localStorage.getItem("user_data");
  if (
    user_profile &&
    user_profile !== undefined &&
    user_profile !== "undefined" &&
    user_profile !== null
  ) {
    return JSON.parse(localStorage.getItem("user_data"));
  } else {
    return {};
  }
};
const _get_content_setting_localStorage = () => {
  return JSON.parse(localStorage.getItem("content_setting"));
};
const get_page_content_localStorage = () => {
  return JSON.parse(localStorage.getItem("page_content"));
};
const _get_client_dashboard_setting_localStorage = () => {
  return JSON.parse(localStorage.getItem("client_dashboard_setting"));
};
const _get_testmonial_localStorage = () => {
  return JSON.parse(localStorage.getItem("testmonial"));
};
const _get_home_event_localStorage = () => {
  return JSON.parse(localStorage.getItem("home_event"));
};
const _get_admin_time_zone_localStorage = () => {
  return JSON.parse(localStorage.getItem("admin_time_zone"));
};
const get_project_info_localStorage = () => {
  return JSON.parse(localStorage.getItem("project_info"));
};
// const get_consultant_data_localStorage = () => {
//   return JSON.parse(localStorage.getItem("consultant_data"));
// };
const _get_navbar_list_localStorage = () => {
  return JSON.parse(localStorage.getItem("navbar_list"));
};
const _get_is_seen_video_localStorage = () => {
  return localStorage.getItem("is_seen_video");
};
const _get_videos_list_localStorage = () => {
  return localStorage.getItem("video_list");
};
module.exports = {
  _get_user_from_localStorage,
  _get_content_setting_localStorage,
  _get_client_dashboard_setting_localStorage,
  _get_testmonial_localStorage,
  _get_home_event_localStorage,
  _get_admin_time_zone_localStorage,
  get_project_info_localStorage,
  // get_consultant_data_localStorage,
  _get_navbar_list_localStorage,
  get_page_content_localStorage,
  _get_is_seen_video_localStorage,
  _get_videos_list_localStorage,
};
